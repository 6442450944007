import $ from 'jquery';
import { BenutzerData, LoginData, LoginResponse, RegisterBenutzerRequest, SaveBenutzerRequest } from './Data.js';
import { TabsBuilder } from './gui/TabsBuilder.js';
import { AngeboteTab } from './gui/tabs/AngeboteTab.js';
import { AngeboteVerwaltenTab } from './gui/tabs/AngeboteVerwaltenTab.js';
import { EditAngebotModal } from './gui/tabs/EditAngebotModal.js';
import { HomeTab } from './gui/tabs/HomeTab.js';
import { MeinJahrgangTab } from './gui/tabs/MeinJahrgangTab.js';
import { MeineAngeboteTab } from './gui/tabs/MeineAngeboteTab.js';
import { HttpTool } from './tools/Http.js';
import '/assets/css/login.css';
import '/assets/css/main.css';
import { EditBenutzerModal } from './gui/tabs/EditBenutzerModal.js';
import { NutzerVerwaltenTab } from './gui/tabs/NutzerVerwaltenTab.js';
import { SendMessageModal } from './gui/tabs/SendMessageModal.js';
import { StringTool } from './tools/StringTool.js';
import { XLTreffenVerwaltenTab } from './gui/tabs/XLTreffernverwaltenTab.js';
import { BeitrittVerwaltenTab } from './gui/tabs/BeitrittVerwalten.js';


export class Main {
    
    tabsBuilder: TabsBuilder;
    userInfo: BenutzerData;

    newUser: BenutzerData = null;

    homeTab: HomeTab;
    jahrgangTab: MeinJahrgangTab;
    alleAngeboteTab: AngeboteTab;
    meineAngeboteTab: MeineAngeboteTab;
    angeboteVerwaltenTab: AngeboteVerwaltenTab;
    nutzerVerwaltenTab: NutzerVerwaltenTab;
    xlTreffenVerwaltenTab: XLTreffenVerwaltenTab;
    beitrittVerwaltenTab: BeitrittVerwaltenTab;

    editAngebotControl: EditAngebotModal;
    editBenutzerControl: EditBenutzerModal;
    sendMessageControl: SendMessageModal;

    start(){
        this.initLoginClient();
        this.editAngebotControl = new EditAngebotModal();
        this.editBenutzerControl = new EditBenutzerModal();
        this.sendMessageControl = new SendMessageModal(this);
        
        // this.login("asv.pabst@gmail.com", "pw");
    }

    startMain(isAdmin: boolean){
        let $mainDiv = <JQuery<HTMLDivElement>>$('#main-center')
        $mainDiv.empty();
        this.tabsBuilder = new TabsBuilder($mainDiv, 'main-tabs', this);

        this.homeTab = new HomeTab(this.tabsBuilder);
        this.jahrgangTab = new MeinJahrgangTab(this.tabsBuilder);
        this.alleAngeboteTab = new AngeboteTab(this.tabsBuilder);
        this.meineAngeboteTab = new MeineAngeboteTab(this.tabsBuilder);
        if(isAdmin){
            this.angeboteVerwaltenTab = new AngeboteVerwaltenTab(this.tabsBuilder);
            this.nutzerVerwaltenTab = new NutzerVerwaltenTab(this.tabsBuilder);
            this.xlTreffenVerwaltenTab = new XLTreffenVerwaltenTab(this.tabsBuilder);
            this.beitrittVerwaltenTab = new BeitrittVerwaltenTab(this.tabsBuilder);
        }

        $('#button-logout').on('click', () => {
            HttpTool.fetchSimple('/servlet/logout', {});
            window.location.href = "/"
        })

        let titel: string = this.userInfo.titel == null ? "" : this.userInfo.titel + " ";
        let name = titel + this.userInfo.rufname + " " + this.userInfo.familienname;

        $('#header-username').text(name)
        
        this.homeTab.setActive();
        
    }
    
    initLoginClient(){
        let that = this;
        $('#loginPassword').on('keydown', (event) => {
            if(event.key == "Enter"){
                let email = <string>$('#loginMail').val();
                let password = <string>$('#loginPassword').val();
                that.login(email, password);        
            }
        })
        $('#loginButton').on('click', async () => {
            let email = <string>$('#loginMail').val();
            let password = <string>$('#loginPassword').val();
            that.login(email, password);
        })    
        $('#registerButton').on('click', () => {

                this.editBenutzerControl.showModal("Registrieren", this.newUser, async (benutzer, newPassword) => {
                    let request: RegisterBenutzerRequest = {
                        benutzer: benutzer,
                        newPassword: newPassword
                    }

                    this.newUser = benutzer;
    
                    let response = await HttpTool.fetchSimple('/servlet/registerBenutzer', request);     
                    if(response.status == "OK"){
                        alert("Sie sind jetzt in der DES-Datenbank registriert. Ihre Daten werden geprüft. Sobald wir Ihren Zugang freigeschaltet haben, verständigen wir Sie per Mail.");
                    }
                }, false)


        })    
    }

    async login(email: string, password: string){
        let response: LoginResponse = await HttpTool.fetchSimple("/servlet/login", new LoginData(email, password));
        if(response.status == "OK"){
            this.userInfo = response.benutzerData;
            this.userInfo.geburtsdatum = new Date(this.userInfo.geburtsdatum);
            $('#loginBody').hide();
            $('#main').css('display', 'flex');
            

            this.startMain(this.userInfo.is_admin);
        } else {
            // alert(response.message);
        }

    }

}

$(() => {
    new Main().start();
})


