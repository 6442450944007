import { AngebotType, Angebote } from "../../Angebote.js";
import { AlleAngeboteRepsonse, Angebot, AngebotMitBenutzer, BaseResponse, BestaetigeXlAnmeldungRequest, DeleteAngebotRequest as DeleteAngebotRequest, DeleteXlAnmeldungRequest, SaveAngebotRequest, SetGezahltRequest } from "../../Data.js";
import { HttpTool } from "../../tools/Http.js";
import { Modal } from "../../tools/Modal.js";
import { SelectComponent } from "../../tools/SelectComponent.js";
import { Tab, TabsBuilder } from "../TabsBuilder.js";
import { ColumnDefinition, TabulatorFull as Tabulator } from 'tabulator-tables';
import "/node_modules/tabulator-tables/dist/css/tabulator.min.css"
import { XLAnmeldung, XLSettings, XLTreffenData } from "../../xltreffen/XLTreffenData.js";
import { StringTool } from "../../tools/StringTool.js";
import { BeitrittData } from "../../anmeldung/BeitrittData.js";

export interface GetBeitrittserklaerungenRepsonse extends BaseResponse {
    beitrittserklaerungen: BeitrittData[];
}


export class BeitrittVerwaltenTab extends Tab {

    table: Tabulator = null;
    beitrittserklaerungen: BeitrittData[] = [];
    $contentBottom: JQuery<HTMLDivElement>;

    constructor(tabsBuilder: TabsBuilder) {
        super(tabsBuilder, "Beitrittserklärungen verwalten", "beitritt-verwalten-tab");
        this.$contentDiv.empty();

        let $contentTop = $(this.getHtmlUpperContent());
        this.$contentDiv.append($contentTop);
        this.$contentBottom = $('<div></div>');
        this.$contentDiv.append(this.$contentBottom);

        this.fetchData().then(() => {
            this.showBeitrittserklaerungen();
        });

    }

    async fetchData() {
        let response: GetBeitrittserklaerungenRepsonse = await HttpTool.fetchSimple("/servlet/getBeitrittserklaerungen", {});

        if (response.status == "OK") {

            this.beitrittserklaerungen = response.beitrittserklaerungen;
            this.showBeitrittserklaerungen();

        } else {
            alert("Die Liste der neuen Beitrittserklärungen konnte nicht geladen werden." + response.message);
        }

    }

    showBeitrittserklaerungen() {

        let columns: ColumnDefinition[] = [
            { title: "Anrede", field: "anrede", vertAlign: "middle" },
            { title: "Akad. Grad", field: "akademischer_grad", vertAlign: "middle" },
            { title: "Familienname", field: "familienname", headerFilter: true, vertAlign: "middle" },
            { title: "Rufname", field: "rufname", headerFilter: true, vertAlign: "middle" },
            { title: "Jahrgang", field: "abiturjahrgang", headerFilter: true, vertAlign: "middle" },
            { title: "E-Mail", field: "mail", vertAlign: "middle" },
            { title: "Telefon", field: "tel", vertAlign: "middle" }
        ]

        let that = this;

        columns.push(
            {
                title: 'Löschen',
                vertAlign: "middle",
                formatter: (cell) => {
                    let data: AngebotMitBenutzer = cell.getRow().getData();
                    let $button = $(`<button class="btn btn-danger btn-sm bi-x-lg"></button>`);
                    $button.on('click', () => {
                        let id = data.id;
                        let beitrittserklaerung = that.beitrittserklaerungen.find(a => a.id == id);
                        if (confirm("Soll die Beitrittserklärung wirklich gelöscht werden?")) {
                            this.deleteBeitrittserklaerung(beitrittserklaerung);
                        }
                    });
                    return $button[0];
                }
            },

        )

        if (this.table != null){
            this.table.destroy();
            this.$contentBottom.remove();
            this.$contentBottom = $('<div></div>');
            this.$contentDiv.append(this.$contentBottom);    
        } 

        this.table = new Tabulator(this.$contentBottom[0], {
            columns: columns,
            data: this.beitrittserklaerungen,
            layout: "fitDataTable",
            height: "calc(100vh - 450px)",
            rowHeight: 40,
            selectable: false
        })

    }

    async deleteBeitrittserklaerung(a: BeitrittData) {
        let request: DeleteXlAnmeldungRequest = {
            id: a.id
        }
        let resp: BaseResponse = await HttpTool.fetchSimple("/servlet/deleteBeitrittserklaerung", request);
        if (resp != null && resp.status == "OK") {
            this.beitrittserklaerungen.splice(this.beitrittserklaerungen.indexOf(a), 1);
            this.showBeitrittserklaerungen();
        }
    }

    getHtmlUpperContent(): JQuery<HTMLDivElement> {

        let $upperDiv = <JQuery<HTMLDivElement>>$('<div class="editXltreffenSettings"></div>');


        let $actionDiv = $(`<div class="beitritt-actions"></div>`)
        $upperDiv.append($actionDiv);


        $actionDiv.append(jQuery(`<a href="/servlet/getBeitrittserklaerungenExcelList?x-token-pm=${HttpTool.token}"><button type="button" class="btn btn-primary btn-sm bi-cloud-download" id="buttonDownloadBeitrittXlList" style="height: 38px; margin-top: 10px"> Liste herunterladen...</button></a>
        `));

        let removeButton = jQuery(`<button type="button" class="btn btn-danger btn-sm bi-exclamation-triangle" id="buttonRemoveBeitrittList" style="height: 38px; margin-top: 10px"> Liste komplett löschen...</button>`);
        $actionDiv.append(removeButton);

        $actionDiv.append(jQuery(`<a href="/beitritt.html" target="_blank"><button type="button" class="btn btn-primary btn-sm bi-arrow-right" id="buttonViewBeitrittPage" style="height: 38px; margin-top: 10px"> Web-Formular ansehen...</button></a>
        `));

        removeButton.on('click', async () => {
            if(confirm("Wirklich alle Einträge aus der Liste löschen?")){
                let status = await HttpTool.removeAllBeitrittserklaerungen();
                if(status){
                    this.table.clearData();
                }
            }
        })

        return $upperDiv;
    }

}