import { SendMessageRequest } from "../../Data.js";
import { Main } from "../../Main.js";
import { HttpTool } from "../../tools/Http.js";

export class SendMessageModal {


    $html: JQuery<HTMLDivElement>;
    recipientId: number;

    constructor(public main: Main) {
        this.makeHtml();

        this.$html[0].addEventListener('shown.bs.modal', () => {
            this.$html.find('textarea')[0].focus()
        })
    }

    showModal(caption: string, recipientName: string, recipientId: number) {
        this.recipientId = recipientId;
        this.$html.find('.modal-title').text(caption);
        $('#recipient-name').text(recipientName);
        $('#from').text(this.main.userInfo.email);

        //@ts-ignore
        var myModal = bootstrap.Modal.getOrCreateInstance('#send-mail');
        myModal.show();
    }

    async onSend(message: string) {
        let request: SendMessageRequest = {
            message: message,
            to_id: this.recipientId
        }
        
        await HttpTool.fetchSimple('/servlet/sendMessage', request);
        alert("Die Nachricht wurde versandt.");
    }

    makeHtml() {

        this.$html = $(
            `
<div class="modal" tabindex="-1" id="send-mail">
  <div class="modal-dialog"> 
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Überschrift</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Abbrechen</button>
        <button type="button" class="btn btn-success bi-send" data-bs-dismiss="modal"> Senden</button>
      </div>
    </div>
  </div>
</div>

        `);

        $('body').append(this.$html);

        this.$html.find('.btn-success').on('click', () => this.onSend(<string>$('#message').val()))

        let $form = this.$html.find('.modal-body');

        

        $form.append($(`
                    <div class="mb-3 mt-3">
                       <label for="message" >Nachricht an <span id="recipient-name"></span>:</label>
                       <textarea class="form-control" rows="10" id="message"></textarea>
                    </div>  
                    `));

        $form.append($('<div>Im Vorspann der Nachricht wird der Empfängerin/dem Empfänger Ihre Mailadresse <span id="from" style="color: blue"></span> genannt. Falls diese nicht mehr korrekt ist, können Sie sie im Reiter "Home" bearbeiten.</div>"'));


    }





}