type InputFieldKind = "typ" | "text" | "textarea";

export type AngebotField = {
    id: string,
    caption: string,
    inputKind: InputFieldKind
}

export type AngebotType = {
    id: string,
    caption: string,
    fields: AngebotField[]
}



export class Angebote {

    static field_typ: AngebotField = {id: 'typ', caption: "Typ", inputKind: "typ"};
    
    static field_art: AngebotField = {id: 'art', caption: 'Art', inputKind: "text"};
    static field_branche: AngebotField = {id: 'branche', caption: 'Branche', inputKind: "text"};
    static field_organisation: AngebotField = {id: 'organisation', caption: 'Organisation', inputKind: "text"};
    static field_beruf: AngebotField = {id: 'beruf', caption: 'Beruf', inputKind: "text"};
    static field_schule_uni: AngebotField = {id: 'schule_uni', caption: 'Schule/Uni', inputKind: "text"};
    static field_studiengang: AngebotField = {id: 'studiengang', caption: 'Studiengang', inputKind: "text"};
    static field_plz: AngebotField = {id: 'plz', caption: 'Plz', inputKind: "text"};
    static field_stadt: AngebotField = {id: 'stadt', caption: 'Stadt', inputKind: "text"};

    static field_comment: AngebotField = {id: 'kommentar', caption: "Kommentar", inputKind: "textarea"};

    static typesWithFields: AngebotType[] = [
        {id: "internship_secondary", caption: "Praktika für Schüler/innen",
         fields: [this.field_branche, this.field_organisation, this.field_plz, this.field_stadt]},
        {id: "internship_tertiary", caption: "Praktika für Student/innen",
         fields: [this.field_branche, this.field_organisation, this.field_plz, this.field_stadt]},
        {id: "internship_other", caption: "Sonstige Praktika",
         fields: [this.field_art, this.field_branche, this.field_organisation, this.field_plz, this.field_stadt]},
        {id: "academic", caption: "Studienberatung",
         fields: [this.field_schule_uni, this.field_studiengang]},
        {id: "career", caption: "Berufsberatung",
         fields: [this.field_branche, this.field_beruf]},
        {id: "thesis", caption: "Vermittlung von Abschlussarbeiten",
        fields: [this.field_branche, this.field_organisation, this.field_plz, this.field_stadt]},
        {id: "other", caption: "Weitere Hilfsangebote",
        fields: [this.field_art]}
    ]

    static fields: AngebotField[] = [
        Angebote.field_typ, Angebote.field_art, Angebote.field_branche,
        Angebote.field_organisation, Angebote.field_beruf, Angebote.field_schule_uni,
        Angebote.field_studiengang, Angebote.field_plz, Angebote.field_stadt,
        Angebote.field_comment
    ]


}