import { BenutzerFields } from "../../BenutzerFields.js";
import { BenutzerData } from "../../Data.js";
import { SelectComponent } from "../../tools/SelectComponent.js";

export class EditBenutzerModal {


    $html: JQuery<HTMLDivElement>;

    currentBenutzer: BenutzerData;
    callback: (a: BenutzerData, newPassword: string) => void;

    is_admin: boolean = false;

    anredeSelect: SelectComponent<string>;
    selectedAnrede: string = "F";

    myModal: any;

    constructor() {
        this.makeHtml();

        this.$html[0].addEventListener('shown.bs.modal', () => {
            this.anredeSelect.$component[0].focus()
        })

        let checkboxDatenschutz = $('#datenschutz_einverstanden');
        let buttonSave = $('#buttonRegistrieren');

        buttonSave.on('click', (e) => {
            if(!this.is_admin && !checkboxDatenschutz.is(':checked')){
                alert('Die Speicherung der Daten ist nur möglich, wenn Sie Ihr Einverständnis in die Datenspeicherung erklärt haben. Bitte setzen Sie das entsprechende Häkchen.');
                e.preventDefault();
                e.stopPropagation();
            } else {
                this.onSave();
                this.myModal.hide();
                this.myModal.dispose();
            }
        })

    }

    showModal(caption: string, benutzer: BenutzerData, callback: (a: BenutzerData, newPassword: string) => void, is_admin: boolean) {
        this.is_admin = is_admin;
        this.currentBenutzer = benutzer;
        this.callback = callback;
        this.$html.find('.modal-title').text(caption);

        if(is_admin){
            this.$html.find(".notusereditable").show();
            this.$html.find("#datenschutz_einverstanden_div").hide();
        } else {
            this.$html.find(".notusereditable").hide();
            this.$html.find("#datenschutz_einverstanden_div").show();
        }

        let isRegistrierung = benutzer == null;

        if (this.currentBenutzer == null) {
            this.currentBenutzer = {
                anschrift: "",
                name: "",
                id: null,
                nummer_alt: 0,
                anrede: "",
                titel: "",
                rufname: "",
                familienname: "",
                geburtsname: "",
                geburtsdatum: new Date(),
                abschlussjahr: 0,
                land: "",
                plz: "",
                ort: "",
                adresszeile1: "",
                adresszeile2: "",
                tel_privat: "",
                tel_arbeit: "",
                tel_mobil: "",
                email: "",
                is_mitglied: false,
                is_sonstige: false,
                is_lehrkraft: false,
                is_admin: false,
                aktiviert: false,
                reaktiviert: false,
                state: "",
                last_state_change_date: ""
            }
            benutzer = this.currentBenutzer;
        }


        for (let field of BenutzerFields.fields.filter(f => ( (is_admin && f.admin_editable) || f.user_editable))) {
            let $field = $(`#benutzer_${field.id}`);
            switch (field.inputKind) {
                case "text":
                    $field.val(benutzer == null || benutzer[field.id] == null ? "" : benutzer[field.id]);
                    break;
                case "int":
                    $field.val(benutzer == null || benutzer[field.id] == null ? "" : benutzer[field.id]);
                    break;
                case "boolean":
                    $field.prop("checked", benutzer[field.id]);
                    break;
                case "anrede":
                    this.anredeSelect.selectByObject(benutzer[field.id]);
                    this.selectedAnrede = benutzer[field.id];
                    break;
                case "date":
                    let dateObj = benutzer[field.id];
                    if (dateObj == null) {
                        $field.val("");
                    } else {
                        let day = ("0" + dateObj.getDate()).slice(-2);
                        let month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
                        let d = dateObj.getFullYear() + "-" + (month) + "-" + (day);
                        $field.val(d);
                    }
                    break;
            }
        }

        //@ts-ignore
        this.myModal = bootstrap.Modal.getOrCreateInstance('#edit_benutzer');
        this.myModal.show();


    }

    onSave() {

        for (let field of BenutzerFields.fields.filter(f => ((this.is_admin && f.admin_editable)|| f.user_editable))) {
            let $field = $(`#benutzer_${field.id}`);
            switch (field.inputKind) {
                case "text":
                    this.currentBenutzer[field.id] = $field.val();
                    break;
                case "int":
                    this.currentBenutzer[field.id] = $field.val();
                    break;
                case "boolean":
                    this.currentBenutzer[field.id] = $field.is(':checked');
                    break;
                case "date":
                    this.currentBenutzer[field.id] = new Date(<string>$field.val());
                    break;
                case "anrede":
                    this.currentBenutzer[field.id] = this.selectedAnrede;
                    break;
            }
        }

        this.currentBenutzer.anrede = <string>this.anredeSelect.$component.val();

        let password1 = <string>$('#benutzer_password1').val();
        let password2 = <string>$('#benutzer_password2').val();

        if (password1 != "") {
            if (password1 != password2) {
                alert('Die Passwörter stimmen nicht überein, daher wir das alte Passwort beibehalten.');
                password1 = null;
            }
        } else {
            password1 = null;
        }

        if (this.callback != null) {
            this.callback(this.currentBenutzer, password1);
        }
    }

    makeHtml() {

        this.$html = $(
            `
<div class="modal" tabindex="-1" id="edit_benutzer">
  <div class="modal-dialog" style="width: 700px; max-width: 700px"> 
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Überschrift</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Abbrechen</button>
        <button type="button" class="btn btn-success" id="buttonRegistrieren">Speichern</button>
      </div>
    </div>
  </div>
</div>

        `);

        $('body').append(this.$html);

        let $form = this.$html.find('.modal-body');
        
        for (let field of BenutzerFields.fields.filter((f) => f.admin_editable)) {
            let notUserEditableClass = field.user_editable ? "" : " notusereditable";
            switch (field.inputKind) {
                case "text":
                    $form.append($(`
                    <div class="mb-3 input-group-sm input-group${notUserEditableClass}">
                       <span class="input-group-text">${field.caption}:</span>
                       <input type="text" class="form-control" id="benutzer_${field.id}">
                    </div>  
                    `));
                    break;
                case "int":
                    $form.append($(`
                    <div class="mb-3 input-group-sm input-group${notUserEditableClass}">
                       <span class="input-group-text">${field.caption}:</span>
                       <input type="number" class="form-control" id="benutzer_${field.id}">
                    </div>  
                    `));
                    break;
                case "textarea":
                    $form.append($(`
                    <div class="mb-3 mt-3${notUserEditableClass}">
                       <label for="benutzer_${field.id}" >${field.caption}:</label>
                       <textarea class="form-control" rows="5" id="benutzer_${field.id}"></textarea>
                    </div>  
                    `));
                    break;
                case "anrede":
                    this.anredeSelect = new SelectComponent<string>('Anrede:', $form, (item) => {
                        this.selectedAnrede = item.object;
                    });

                    this.anredeSelect.addItem("Frau", "F", "F");
                    this.anredeSelect.addItem("Herr", "H", "H");
                    break;
                case "boolean":
                    $form.append($(`
                    <div class="form-check${notUserEditableClass}">
                    <input type="checkbox" class="form-check-input" id="benutzer_${field.id}">
                    <label class="form-check-label" for="benutzer_${field.id}">${field.caption}</label>
                    </div>  
                    `));
                    break;
                case "date":
                    $form.append($(`
                    <div class="mb-3 input-group-sm input-group${notUserEditableClass}">
                       <span class="input-group-text">${field.caption}:</span>
                       <input type="date" class="form-control" id="benutzer_${field.id}">
                    </div>  
                    `));

                    break;
            }

        }

            $form.append($(`
            <div class="form-check" id="datenschutz_einverstanden_div">
            <input type="checkbox" class="form-check-input" id="datenschutz_einverstanden">
            <label class="form-check-label" for="datenschutz_einverstanden">Ich bin mit der Datenspeicherung und -verarbeitung einverstanden</label>
            </div>  
            `));

        $form.append($(`
        <div style="margin-top: 10px; font-weight: bold; height: unset">Neues Passwort setzen (optional):</div>
        <div class="mb-3 input-group-sm input-group">
           <span class="input-group-text">Passwort:</span>
           <input type="password" class="form-control" id="benutzer_password1">
        </div>  
        <div class="mb-3 input-group-sm input-group">
           <span class="input-group-text">Wiederholen:</span>
           <input type="password" class="form-control" id="benutzer_password2">
        </div>  
        `));


        $form.append($(`<div style="grid-column: span 2; font-size: 80%"><div style="font-weight: bold">Passwortregeln:</div> Mindestens 8 Zeichen lang, Kleinbuchstaben, Großbuchstaben, Ziffern</div>`))


    }





}