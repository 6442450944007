import { BenutzerData, SaveBenutzerRequest, SaveBenutzerResponse } from "../../Data.js";
import { HttpTool } from "../../tools/Http.js";
import { Tab, TabsBuilder } from "../TabsBuilder.js";

export class HomeTab extends Tab {
    constructor(tabsBuilder: TabsBuilder){
        super(tabsBuilder, "Home", "home-tab");
        this.$contentDiv.append(this.getHtmlUpperContent());
    }



    getHtmlUpperContent(): JQuery<HTMLDivElement> {
        let $upperDiv = <JQuery<HTMLDivElement>>$('<div></div>');

        $upperDiv.append($(`
            <h1>Willkommen im DES-Informationssystem des CSG-Freundekreises!</h1>
            DES steht für "Datenbank ehemaliger Schüler/innen". Ehemalige Scheiner-Schüler/innen können hier
            <ul>
                <li>Kontakt zu anderen Schüler/innen aus ihrem Jahrgang aufnehmen</li>
                <li>Neues aus dem CSG erfahren</li>
                <li>Angebote für Praktika, Berufsinformationen usw. einstellen, um aktuellen und ehemaligen Schüler/innen des CSG weiterzuhelfen.</li>
            </ul>
        `));

        let $buttonEditOwnData = $(`
        <button type="button" class="btn btn-success btn-sm bi-pencil" id="buttonEditOwnData" style="height: 38px;"> Eigene Daten (auch Passwort) bearbeiten...</button>
        `);
        $upperDiv.append($buttonEditOwnData);

        $buttonEditOwnData.on('click', () => {

            this.tabsBuilder.main.editBenutzerControl.showModal('Eigene Daten bearbeiten', this.tabsBuilder.main.userInfo, async (a: BenutzerData, pw: string) => {
                let request: SaveBenutzerRequest = {
                    benutzer: a,
                    newPassword: pw
                }

                await HttpTool.fetchSimple('/servlet/saveBenutzer', request);

                this.tabsBuilder.main.jahrgangTab.fetchData();
                
            }, false)


        })

        $upperDiv.append($buttonEditOwnData);

        return $upperDiv;

    }
}