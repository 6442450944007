import { JahrgangBenutzerData, MeinJahrgangRepsonse } from "../../Data.js";
import { HttpTool } from "../../tools/Http.js";
import { Tab, TabsBuilder } from "../TabsBuilder.js";
import { ColumnDefinition, TabulatorFull as Tabulator } from 'tabulator-tables';
import "/node_modules/tabulator-tables/dist/css/tabulator.min.css"

export class MeinJahrgangTab extends Tab {

    data: JahrgangBenutzerData[] = [];
    filteredData: JahrgangBenutzerData[] = [];
    table: Tabulator;
    $contentBottom: JQuery<HTMLDivElement>;

    constructor(tabsBuilder: TabsBuilder) {
        super(tabsBuilder, "Ehemalige finden", "jahrgang-tab");
        this.$contentDiv.empty();

        let $contentTop = $(this.getHtmlUpperContent());
        this.$contentDiv.append($contentTop);
        this.$contentBottom = $('<div></div>');
        this.$contentDiv.append(this.$contentBottom);

        this.fetchData().then(() => {
            this.updateGrid();
        });

    }

    updateGrid(){
        let userAbschlussjahr = this.tabsBuilder.main.userInfo.abschlussjahr;

        if($('#nurMeinJahrgang').is(":checked")){
            this.filteredData = this.data.filter((benutzer) => benutzer.abschlussjahr == userAbschlussjahr);
        } else {
            this.filteredData = this.data.slice();
        }

        if(this.table == null){
            this.table = new Tabulator(this.$contentBottom[0], {
                columns: [
                    { title: "akad. Grad", field: "titel",  sorter: "string"},
                    { title: "Familienname", field: "familienname", sorter: "string", headerFilter: true},
                    { title: "Rufname", field: "rufname",  sorter: "string", headerFilter: true},
                    { title: "Geburtsname", field: "geburtsname",  sorter: "string", headerFilter: true},
                    { title: "Abschlussjahr", field: "abschlussjahr",  sorter: "number", headerFilter: true},
                    { title: "Kontakt", formatter: (cell) => {
                        let $button = $(`<button class="btn btn-primary btn-sm bi-send"> Kontakt...</button>`);
                        $button.on('click', () => {
                            let data: JahrgangBenutzerData = cell.getRow().getData();
                            let name: string = data.rufname + " " + data.familienname;
                            this.tabsBuilder.main.sendMessageControl.showModal("Nachricht senden", name, data.id);
                        })
                        return $button[0];
                    }}
                ],
                data: this.data,
                layout: "fitDataTable",
                height: "calc(100vh - 300px)",
                selectable: false
            })

            this.table.on('tableBuilt', () => {
                let abschlussjahr = this.tabsBuilder.main.userInfo.abschlussjahr;
                if(abschlussjahr != null && abschlussjahr > 1900){
                    this.table.setHeaderFilterValue("abschlussjahr" , "" + abschlussjahr )
                }
            })

        } else {
            this.table.updateData(this.data);
        }     
    }


    async fetchData() {
        let response: MeinJahrgangRepsonse = await HttpTool.fetchSimple("/servlet/meinJahrgang", {});

        if (response.status == "OK") {

            this.data = response.benutzerListe;

        } else {
            alert(response.message);
            return []
        }

    }


    getHtmlUpperContent(): string {
        return `<div>
                </div>
        `;
    }
}