import { Main } from "../Main.js";

export class Tab {
    $navLi: JQuery<HTMLLIElement>;
    $contentDiv: JQuery<HTMLDivElement>;
    $button: JQuery<HTMLButtonElement>;

    constructor(public tabsBuilder: TabsBuilder, public caption: string, public id: string){
        this.$navLi = $(`
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="${id}" data-bs-toggle="tab" data-bs-target="#${id}-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">${caption}</button>
        </li>
        `);
        tabsBuilder.$navTabsUl.append(this.$navLi);
        this.$button = $(`#${id}`);

        this.$button.on('click', () => {this.onTabActivated()})

        this.$contentDiv = $(`
        <div class="tab-pane fade show active" id="${id}-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
        </div>
        `);
        tabsBuilder.$tabContentDiv.append(this.$contentDiv);

        tabsBuilder.addTab(this);
    }

    setActive(){
        for(let tab of this.tabsBuilder.tabs){
            tab.$button.removeClass('active');
            tab.$contentDiv.removeClass('active');
        }        
        this.$button.addClass('active');
        this.$contentDiv.addClass('active');
        this.onTabActivated();
    }

    onTabActivated(){
        for(let tab of this.tabsBuilder.tabs){
            if(tab != this) tab.onTabDeactivated();
        }
    }

    onTabDeactivated(){

    }
}


export class TabsBuilder {
    
    tabs: Tab[] = [];
    idToTabMap: {[id: string]:Tab} = {};

    $navTabsUl: JQuery<HTMLUListElement>;
    $tabContentDiv: JQuery<HTMLUListElement>; 

    constructor(public $outerDiv: JQuery<HTMLDivElement>, id: string, public main: Main){
        this.$navTabsUl = $(`
        <ul class="nav nav-tabs" id="${id}-headers" role="tablist">
        `);
        $outerDiv.append(this.$navTabsUl);
        this.$tabContentDiv = $(`
        <div class="tab-content" id="${id}-content">
        `)
        $outerDiv.append(this.$tabContentDiv);
    }

    addTab(tab: Tab){
        this.tabs.push(tab);
        this.idToTabMap[tab.id] = tab;
    }


}