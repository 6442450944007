export class Modal {

    static show(title: string, body: string) {

        let $modal = $('#pm-modal');
        $modal.remove();

        $modal = $(`
        
<div class="modal" tabindex="-1" id="pm-modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">${title}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      ${body}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
      </div>
    </div>
  </div>
</div>
         `)
        $("body").append($modal);

         //@ts-ignore
         var myModal = bootstrap.Modal.getOrCreateInstance('#pm-modal');
         myModal.show();
    }




}