export class LoginData {
    constructor(public email: string, public password: string){}
}

export interface BaseResponse {
    status: string,
    message: string
}

export interface LoginResponse extends BaseResponse {
    token: string,
    benutzerData: BenutzerData
}

export type BenutzerData = {

// aggregated fields:
anschrift: string,
name: string,

  
// DB-Fields:
id: number,
nummer_alt: number,

anrede: string,

titel: string,

rufname: string,

familienname: string,

geburtsname: string,

geburtsdatum: string | Date,

abschlussjahr: number,

land: string,

plz: string,

ort: string,

adresszeile1: string,

adresszeile2: string,
tel_privat: string,

tel_arbeit: string,

tel_mobil: string,

email: string,

is_mitglied: boolean,

is_sonstige: boolean,

is_lehrkraft: boolean,

is_admin: boolean,

aktiviert: boolean,

reaktiviert: boolean,

state: string,

last_state_change_date: string

}

export type JahrgangBenutzerData = {

id: number, 

anrede: string,

titel: string,

rufname: string,

familienname: string,

geburtsname: string,

abschlussjahr: number


}


export interface MeinJahrgangRepsonse extends BaseResponse {
    benutzerListe: JahrgangBenutzerData[]
}

export type Angebot = {
    id: number,
    benutzer_id: number,
    typ: string,
    art: string,
    branche: string,
    organisation: string,
    beruf: string,
    schule_uni: string,
    studiengang: string,
    plz: string,
    stadt: string,
    kommentar: string,
    aktiv: boolean
}

export type AngebotMitBenutzer = {
    name: string,
    anrede: string, 
    titel: string,
    familienname: string,
    rufname: string,
    id: number,
    benutzer_id: number,
    typ: string,
    art: string,
    branche: string,
    organisation: string,
    beruf: string,
    schule_uni: string,
    studiengang: string,
    plz: string,
    stadt: string,
    kommentar: string,
    aktiv: boolean
}


export interface AlleAngeboteRepsonse extends BaseResponse {
    angebotListe: AngebotMitBenutzer[]
}

export type SaveAngebotRequest = {
    angebot: Angebot
}

export interface SaveAngebotResponse extends BaseResponse {
    id: number
}

export type DeleteAngebotRequest = {
    id: number
}

export type DeleteXlAnmeldungRequest = {
    id: number
}

export type BestaetigeXlAnmeldungRequest = {
    id: number
}

export type SetGezahltRequest = {
    id: number,
    hat_gezahlt: boolean
}


export interface AlleBenutzerRepsonse extends BaseResponse {
    benutzerListe: BenutzerData[]
}

export type SaveBenutzerRequest = {
    benutzer: BenutzerData,
    newPassword: string      // null => password won't get changed
}

export type RegisterBenutzerRequest = {
    benutzer: BenutzerData,
    newPassword: string      // null => password won't get changed
}

export interface SaveBenutzerResponse extends BaseResponse {
    id: number
}

export interface SaveBenutzerResponse extends BaseResponse {
}

export type DeleteBenutzerRequest = {
    id: number
}

export type SendMessageRequest = {
    to_id: number,
    message: string
}

export type PasswordResetRequest = {
    token: string,
    newPassword: string
}

export type DeleteDataRequest = {
    token: string
}