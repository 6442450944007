import { AngebotType, Angebote } from "../../Angebote.js";
import { Angebot } from "../../Data.js";
import { SelectComponent } from "../../tools/SelectComponent.js";

export class EditAngebotModal {


    $html: JQuery<HTMLDivElement>;

    selectedAngebotTyp: AngebotType;

    typSelect: SelectComponent<AngebotType>;

    currentAngebot: Angebot;
    callback: (a: Angebot) => void;

    constructor() {
        this.makeHtml();
        
        this.$html[0].addEventListener('shown.bs.modal', () => {
          this.typSelect.$component[0].focus()
        })
    }

    showModal(caption: string, angebot: Angebot, callback: (a: Angebot) => void) {
        this.currentAngebot = angebot;
        this.callback = callback;
        this.$html.find('.modal-title').text(caption);

        if(angebot != null){
            this.selectedAngebotTyp = Angebote.typesWithFields.find(a => a.id == angebot.typ);
            this.typSelect.selectByObject(this.selectedAngebotTyp);
        } else {
            this.selectedAngebotTyp = Angebote.typesWithFields[0];
        }

        for(let field of Angebote.fields.filter(f => f.id != 'typ')){
            let $field = $(`#angebot_${field.id}`);
            $field.val(angebot == null || angebot[field.id] == null ? "" : angebot[field.id]);
        }

        this.setFieldVisibility();

        //@ts-ignore
        var myModal = bootstrap.Modal.getOrCreateInstance('#edit_angebot');
        myModal.show();
    }

    setFieldVisibility(){
        for(let field of Angebote.fields.filter(f => f.inputKind == "text")){
            let $field = $(`#angebot_${field.id}`);
            if(this.selectedAngebotTyp.fields.indexOf(field) >= 0){
                $field.parent().show();
            } else {
                $field.parent().hide();
            }
        }
    }

    onSave(){
        if(this.currentAngebot == null){
            this.currentAngebot = {
                art: "",
                aktiv: true,
                benutzer_id: null,
                beruf: "",
                branche: "",
                id: null,
                kommentar: "",
                organisation: "",
                plz: "",
                schule_uni: "",
                stadt: "",
                studiengang: "",
                typ: ""
            }
        }

        for(let field of Angebote.fields.filter(f => f.id != 'typ')){
            let $field = $(`#angebot_${field.id}`);
            this.currentAngebot[field.id] = $field.val();
        }

        this.currentAngebot.typ = this.selectedAngebotTyp.id;

        if(this.callback != null){
            this.callback(this.currentAngebot);
        }
    }

    makeHtml() {

        this.$html = $(
        `
<div class="modal" tabindex="-1" id="edit_angebot">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Überschrift</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Abbrechen</button>
        <button type="button" class="btn btn-success" data-bs-dismiss="modal">Speichern</button>
      </div>
    </div>
  </div>
</div>

        `);

        $('body').append(this.$html);

            this.$html.find('.btn-success').on('click', ()=>this.onSave())

        let $form = this.$html.find('.modal-body');

        for (let field of Angebote.fields) {

            switch (field.inputKind) {
                case "text":
                    $form.append($(`
                    <div class="mb-3 input-group-sm input-group">
                       <span class="input-group-text">${field.caption}:</span>
                       <input type="text" class="form-control" id="angebot_${field.id}">
                    </div>  
                    `));
                    break;
                case "textarea":
                    $form.append($(`
                    <div class="mb-3 mt-3">
                       <label for="angebot_${field.id}" >${field.caption}:</label>
                       <textarea class="form-control" rows="5" id="angebot_${field.id}"></textarea>
                    </div>  
                    `));
                    break;
                case "typ":
                    this.typSelect = new SelectComponent<AngebotType>('Art des Angebots:', $form, (item) => {
                        this.selectedAngebotTyp = item.object;
                        this.setFieldVisibility();
                    });

                    this.typSelect.$component.attr('id', '')

                    for (let a of Angebote.typesWithFields) {
                        this.typSelect.addItem(a.caption, a.id, a);
                    }
                    break;
            }

        }



    }





}