import { AngebotType, Angebote } from "../../Angebote.js";
import { AlleAngeboteRepsonse, AngebotMitBenutzer } from "../../Data.js";
import { HttpTool } from "../../tools/Http.js";
import { Modal } from "../../tools/Modal.js";
import { SelectComponent } from "../../tools/SelectComponent.js";
import { Tab, TabsBuilder } from "../TabsBuilder.js";
import { ColumnDefinition, TabulatorFull as Tabulator } from 'tabulator-tables';
import "/node_modules/tabulator-tables/dist/css/tabulator.min.css"

export class AngeboteTab extends Tab {

    selectedAngebotTyp: AngebotType = Angebote.typesWithFields[0];
    table: Tabulator;
    angebote: AngebotMitBenutzer[] = [];
    $contentBottom: JQuery<HTMLDivElement>;

    constructor(tabsBuilder: TabsBuilder) {
        super(tabsBuilder, "Angebote einsehen", "angebote-einsehen-tab");
        this.$contentDiv.empty();

        let $contentTop = $(this.getHtmlUpperContent());
        this.$contentDiv.append($contentTop);
        this.$contentBottom = $('<div></div>');
        this.$contentDiv.append(this.$contentBottom);

        this.fetchData().then(() => {
            this.showAngebote();
        });
    }

    async fetchData() {
        let response: AlleAngeboteRepsonse = await HttpTool.fetchSimple("/servlet/alleAngebote", {});

        if (response.status == "OK") {

            this.angebote = response.angebotListe;
            this.angebote.forEach((a) => { a.name = (a.anrede == "H" ? "Herr" : "Frau") + " " + a.titel + " " + a.rufname + " " + a.familienname })
            this.showAngebote();

        } else {
            alert("Die Liste der Angebote konnte nicht geladen werden." + response.message);

        }

    }

    showAngebote() {

        let columns: ColumnDefinition[] = this.selectedAngebotTyp.fields.map((f) => { return { title: f.caption, field: f.id, headerFilter: true } });
        columns.unshift({ title: "Name", field: "name", headerFilter: true });

        columns.push(
            {
                title: 'Kommentar',
                formatter: (cell) => {
                    let data: AngebotMitBenutzer = cell.getRow().getData();
                    if (data.kommentar?.length > 1) {
                        let $button = $(`<button class="btn btn-primary btn-sm bi-search"> ansehen...</button>`);
                        $button.on('click', () => {
                            Modal.show("Kommentar zum Angebot:", `${data.kommentar}`)
                        });
                        return $button[0];
                    } else {
                        return $('<div></div>')[0];
                    }
                }
            },
            {
                title: 'Kontakt',
                formatter: (cell) => {
                    let $button = $(`<button class="btn btn-primary btn-sm bi-send"> Nachricht senden...</button>`);
                    $button.on('click', () => {
                        let data: AngebotMitBenutzer = cell.getRow().getData();
                        this.tabsBuilder.main.sendMessageControl.showModal(" Nachricht senden", data.name, data.benutzer_id);
                    })
                    return $button[0];
                }
            },

        )



        if (this.table != null){
            this.table.destroy();
            this.$contentBottom.remove();
            this.$contentBottom = $('<div></div>');
            this.$contentDiv.append(this.$contentBottom);    
        } 

        this.table = new Tabulator(this.$contentBottom[0], {
            columns: columns,
            data: this.angebote,
            layout: "fitDataTable",
            height: "calc(100vh - 450px)",
            selectable: false
        })

        this.table.on('tableBuilt', () => {
            this.table.addFilter('typ', '=', this.selectedAngebotTyp.id);
        })


    }



    getHtmlUpperContent(): JQuery<HTMLDivElement> {
        let $upperDiv = <JQuery<HTMLDivElement>>$('<div></div>');
        let $caption = $('<h3>Angebote für Schüler/innen und Student/innen</h3>')
        $upperDiv.append($caption);

        let select = new SelectComponent<AngebotType>('Art des Angebots:', $upperDiv, (item) => {
            this.selectedAngebotTyp = item.object;
            this.showAngebote();
        });

        for (let a of Angebote.typesWithFields) {
            select.addItem(a.caption, a.id, a);
        }

        return $upperDiv;
    }
}