import { AngebotType, Angebote } from "../../Angebote.js";
import { AlleAngeboteRepsonse, Angebot, AngebotMitBenutzer, BaseResponse, BestaetigeXlAnmeldungRequest, DeleteAngebotRequest as DeleteAngebotRequest, DeleteXlAnmeldungRequest, SaveAngebotRequest, SetGezahltRequest } from "../../Data.js";
import { HttpTool } from "../../tools/Http.js";
import { Modal } from "../../tools/Modal.js";
import { SelectComponent } from "../../tools/SelectComponent.js";
import { Tab, TabsBuilder } from "../TabsBuilder.js";
import { ColumnDefinition, TabulatorFull as Tabulator } from 'tabulator-tables';
import "/node_modules/tabulator-tables/dist/css/tabulator.min.css"
import { XLAnmeldung, XLSettings, XLTreffenData } from "../../xltreffen/XLTreffenData.js";
import { StringTool } from "../../tools/StringTool.js";

export interface GetXlAnmeldungenRepsonse extends BaseResponse {
    anmeldungen: XLAnmeldung[];
}


export class XLTreffenVerwaltenTab extends Tab {

    table: Tabulator = null;
    anmeldungen: XLAnmeldung[] = [];
    $contentBottom: JQuery<HTMLDivElement>;

    constructor(tabsBuilder: TabsBuilder) {
        super(tabsBuilder, "XL-Treffen verwalten", "xltreffen-verwalten-tab");
        this.$contentDiv.empty();

        let $contentTop = $(this.getHtmlUpperContent());
        this.$contentDiv.append($contentTop);
        this.$contentBottom = $('<div></div>');
        this.$contentDiv.append(this.$contentBottom);

        XLTreffenData.fetchSettings().then((xlSettings => {
            $('#xlDatum').val(xlSettings.datum);
            $('#betragMitglieder').val(StringTool.format2Dez(xlSettings.betragMitglieder));
            $('#betragNichtmitglieder').val(StringTool.format2Dez(xlSettings.betragNichtmitglieder));
            $('#betragKinder').val(StringTool.format2Dez(xlSettings.betragKinder));
            $('#xlEnabled').prop('checked', xlSettings.enabled);
        }));

        this.fetchData().then(() => {
            this.showAnmeldungen();
        });


    }

    async fetchData() {
        let response: GetXlAnmeldungenRepsonse = await HttpTool.fetchSimple("/servlet/getXlAnmeldungen", {});

        if (response.status == "OK") {

            this.anmeldungen = response.anmeldungen;
            this.showAnmeldungen();

        } else {
            alert("Die Liste der Anmeldungen konnte nicht geladen werden." + response.message);
        }

    }

    showAnmeldungen() {

        let columns: ColumnDefinition[] = [
            { title: "Anrede", field: "anrede", vertAlign: "middle" },
            { title: "Akad. Grad", field: "akademischer_grad", vertAlign: "middle" },
            { title: "Familienname", field: "familienname", headerFilter: true, vertAlign: "middle" },
            { title: "Rufname", field: "rufname", headerFilter: true, vertAlign: "middle" },
            { title: "Jahrgang", field: "abiturjahrgang", headerFilter: true, vertAlign: "middle" },
            { title: "E-Mail", field: "mail", vertAlign: "middle" },
            { title: "Telefon", field: "tel", vertAlign: "middle" }
        ]

        let that = this;

        columns.push(
            {
                title: 'Gezahlt',
                field: 'hat_gezahlt',
                formatter: (cell, formatterParams, onRendered) => {
                    if (cell.getValue()) {
                        return $(`<i class='bi-check des-check'></i>`)[0];
                    } else {
                        return $(`<i class='bi-x des-cross'></i>`)[0];
                    }
                },
                hozAlign: "center",
                vertAlign: "middle",
                cellClick: async (e, cell) => {
                    let newValue = !cell.getValue();
                    let id = <number>cell.getRow().getData().id;
                    let anmeldung = that.anmeldungen.find(a => a.id == id);
                    anmeldung.hat_gezahlt = newValue;
                    await that.setGezahlt(anmeldung, false);
                    cell.setValue(newValue);
                }
            },
            // {
            //     title: 'Bestätigt',
            //     field: 'bestaetigt',
            //     formatter: (cell, formatterParams, onRendered) => {
            //         if (cell.getValue()) {
            //             return $(`<i class='bi-check des-check'></i>`)[0];
            //         } else {
            //             return $(`<i class='bi-x des-cross'></i>`)[0];
            //         }
            //     },
            //     hozAlign: "center",
            //     vertAlign: "middle",
            //     cellClick: async (e, cell) => {
            //         let newValue = !cell.getValue();
            //         let id = <number>cell.getRow().getData().id;
            //         let anmeldung = that.anmeldungen.find(a => a.id == id);
            //         anmeldung.hat_gezahlt = newValue;
            //         await that.anmeldungBestaetigen(anmeldung);
            //         cell.setValue(newValue);
            //     }
            // },
            {
                title: 'Löschen',
                vertAlign: "middle",
                formatter: (cell) => {
                    let data: AngebotMitBenutzer = cell.getRow().getData();
                    let $button = $(`<button class="btn btn-danger btn-sm bi-x-lg"></button>`);
                    $button.on('click', () => {
                        let id = data.id;
                        let anmeldung = that.anmeldungen.find(a => a.id == id);
                        if (confirm("Soll die Anmeldung wirklich gelöscht werden?")) {
                            this.deleteAnmeldung(anmeldung);
                        }
                    });
                    return $button[0];
                }
            },

        )

        if (this.table != null){
            this.table.destroy();
            this.$contentBottom.remove();
            this.$contentBottom = $('<div></div>');
            this.$contentDiv.append(this.$contentBottom);    
        } 

        this.table = new Tabulator(this.$contentBottom[0], {
            columns: columns,
            data: this.anmeldungen,
            layout: "fitDataTable",
            height: "calc(100vh - 450px)",
            rowHeight: 40,
            selectable: false
        })

        // this.table.download("xlsx", "anmeldungen.xlsx", {
        //     writeOptions:{
        //         bookType:"xlsx"
        //     }
        // });

    }

    async anmeldungBestaetigen(anmeldung: XLAnmeldung) {
        let request: BestaetigeXlAnmeldungRequest = {
            id: anmeldung.id
        }
        
        let resp: BaseResponse = await HttpTool.fetchSimple("/servlet/bestaetigeXlAnmeldung", request);
        if (resp != null && resp.status != "OK") {
            alert(resp.message);
        }

    }

    async deleteAnmeldung(a: XLAnmeldung) {
        let request: DeleteXlAnmeldungRequest = {
            id: a.id
        }
        let resp: BaseResponse = await HttpTool.fetchSimple("/servlet/deleteXlAnmeldung", request);
        if (resp != null && resp.status == "OK") {
            this.anmeldungen.splice(this.anmeldungen.indexOf(a), 1);
            this.showAnmeldungen();
        }
    }

    async setGezahlt(a: XLAnmeldung, redraw: boolean = true) {
        let request: SetGezahltRequest = {
            id: a.id,
            hat_gezahlt: a.hat_gezahlt
        }

        await HttpTool.fetchSimple('/servlet/setGezahlt', request);

        if (redraw) this.showAnmeldungen();
    }

    getHtmlUpperContent(): JQuery<HTMLDivElement> {



        let $upperDiv = <JQuery<HTMLDivElement>>$('<div class="editXltreffenSettings"></div>');

        $upperDiv.append($(`
         <div class="xl-settings-form-outline">
         <label class="form-label" for="xlDatum" >Datum:</label>
         <input type="date" id="xlDatum" class="form-control" placeholder="Bitte eingeben."/>

         <label class="form-label" for="betragMitglieder">Unkostenbetrag für Mitglieder:</label>
         <input type="text" id="betragMitglieder" class="form-control" placeholder="Bitte eingeben." />
         
         <label class="form-label" for="betragNichtmitglieder">Unkostenbetrag für Nichtmitglieder:</label>
         <input type="text" id="betragNichtmitglieder" class="form-control" placeholder="Bitte eingeben." />
         
         <label class="form-label" for="betragKinder">Unkostenbetrag für Kinder:</label>
         <input type="text" id="betragKinder" class="form-control" placeholder="Bitte eingeben." />
         
         <div class="form-check form-switch" style="margin-left: 2em">
                <label class="form-check-label" for="xlEnabled">(online) </label>
                <input class="form-check-input" type="checkbox" id="xlEnabled">
            </div>
            <div></div>
         </div>
        `));

        let $actionDiv = $(`<div class="xl-actions"></div>`)
        $upperDiv.append($actionDiv);

        let $buttonSaveSettings = $(`
        <button type="button" class="btn btn-success btn-sm bi-pencil" id="buttonSaveSettings" style="height: 38px; margin-top: 10px; grid-column: 5/7"> Einstellungen speichern</button>
        `);

        $upperDiv.find('.xl-settings-form-outline').append($buttonSaveSettings);

        $buttonSaveSettings.on('click', async () => {

            let settings: XLSettings = {
                betragMitglieder: this.parseFloat($('#betragMitglieder').val() + ""),
                betragNichtmitglieder: this.parseFloat($('#betragNichtmitglieder').val() + ""),
                betragKinder: this.parseFloat($('#betragKinder').val() + ""),
                datum: <string>$('#xlDatum').val(),
                enabled: $('#xlEnabled').is(':checked')
            }    
                
            await XLTreffenData.setSettings(settings);
            alert('Die Einstellungen wurden gespeichert.')
        })


        $actionDiv.append(jQuery(`<a href="/servlet/getAnmeldungenExcelList?x-token-pm=${HttpTool.token}"><button type="button" class="btn btn-primary btn-sm bi-cloud-download" id="buttonDownloadXlList" style="height: 38px; margin-top: 10px"> Liste herunterladen...</button></a>
        `));

        let removeButton = jQuery(`<button type="button" class="btn btn-danger btn-sm bi-exclamation-triangle" id="buttonRemoveXlList" style="height: 38px; margin-top: 10px"> Liste komplett löschen...</button>`);
        $actionDiv.append(removeButton);

        $actionDiv.append(jQuery(`<a href="/xl-treffen.html" target="_blank"><button type="button" class="btn btn-primary btn-sm bi-arrow-right" id="buttonViewXlPage" style="height: 38px; margin-top: 10px"> Anmeldeseite ansehen...</button></a>
        `));

        removeButton.on('click', async () => {
            if(confirm("Wirklich alle Einträge aus der Liste löschen?")){
                let status = await HttpTool.removeAllAnmeldungen();
                if(status){
                    this.table.clearData();
                }
            }
        })

        return $upperDiv;
    }

    parseFloat(s: string): number {
        if(!s) return null;
        if(s.length == 0) return null;
        try {
            return Number.parseFloat(s.replace(",", "."));
        } catch (ex){

        }

        return 0;
    }
}