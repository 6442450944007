type InputFieldKind = "typ" | "text" | "textarea" | "anrede" | "date" | "int" | "boolean";

export type BenutzerField = {
    id: string,
    caption: string,
    inputKind: InputFieldKind,
    showInTable: boolean,
    user_editable: boolean,
    admin_editable: boolean
}

export class BenutzerFields {

    static field_anrede: BenutzerField = {id: 'anrede', caption: "Anrede", inputKind: "anrede", showInTable: false, user_editable: true, admin_editable: true};
    static field_titel: BenutzerField = {id: 'titel', caption: "Titel", inputKind: "text", showInTable: false, user_editable: true, admin_editable: true};
    static field_rufname: BenutzerField = {id: 'rufname', caption: "Rufname", inputKind: "text", showInTable: false, user_editable: true, admin_editable: true};
    static field_familienname: BenutzerField = {id: 'familienname', caption: "Familienname", inputKind: "text", showInTable: false, user_editable: true, admin_editable: true};
    static field_geburtsname: BenutzerField = {id: 'geburtsname', caption: "Geburtsname", inputKind: "text", showInTable: false, user_editable: true, admin_editable: true};
    static field_geburtsdatum: BenutzerField = {id: 'geburtsdatum', caption: "Geburtsdatum", inputKind: "date", showInTable: true, user_editable: true, admin_editable: true};
    static field_abschlussjahr: BenutzerField = {id: 'abschlussjahr', caption: "Abschlussjahr", inputKind: "int", showInTable: true, user_editable: true, admin_editable: true};
    static field_land: BenutzerField = {id: 'land', caption: "Land", inputKind: "text", showInTable: false, user_editable: true, admin_editable: true};
    static field_plz: BenutzerField = {id: 'plz', caption: "PLZ", inputKind: "text", showInTable: false, user_editable: true, admin_editable: true};
    static field_ort: BenutzerField = {id: 'ort', caption: "Ort", inputKind: "text", showInTable: false, user_editable: true, admin_editable: true};
    static field_adresszeile1: BenutzerField = {id: 'adresszeile1', caption: "Adresszeile 1", inputKind: "text", showInTable: false, user_editable: true, admin_editable: true};
    static field_adresszeile2: BenutzerField = {id: 'adresszeile2', caption: "Adresszeile 2", inputKind: "text", showInTable: false, user_editable: true, admin_editable: true};
    static field_tel_privat: BenutzerField = {id: 'tel_privat', caption: "Tel. privat", inputKind: "text", showInTable: false, user_editable: true, admin_editable: true};
    static field_tel_arbeit: BenutzerField = {id: 'tel_arbeit', caption: "Tel. Arbeit", inputKind: "text", showInTable: false, user_editable: true, admin_editable: true};
    static field_tel_mobil: BenutzerField = {id: 'tel_mobil', caption: "Tel. mobil", inputKind: "text", showInTable: false, user_editable: true, admin_editable: true};
    static field_email: BenutzerField = {id: 'email', caption: "E-Mail", inputKind: "text", showInTable: true, user_editable: true, admin_editable: true};
    static field_is_mitglied: BenutzerField = {id: 'is_mitglied', caption: "Mitglied FK", inputKind: "boolean", showInTable: true, user_editable: true, admin_editable: true};
    static field_is_lehrkraft: BenutzerField = {id: 'is_lehrkraft', caption: "Lehrkraft", inputKind: "boolean", showInTable: true, user_editable: true, admin_editable: true};
    static field_is_admin: BenutzerField = {id: 'is_admin', caption: "Admin", inputKind: "boolean", showInTable: true, user_editable: false, admin_editable: false};
    static field_aktiviert: BenutzerField = {id: 'aktiviert', caption: "aktiviert", inputKind: "boolean", showInTable: true, user_editable: false, admin_editable: false};
    static field_reaktiviert: BenutzerField = {id: 'reaktiviert', caption: "reaktiviert", inputKind: "boolean", showInTable: true, user_editable: false, admin_editable: false};
    static field_state: BenutzerField = {id: 'state', caption: "Zustand", inputKind: "text", showInTable: true, user_editable: false, admin_editable: false};
    static field_last_state_change_date: BenutzerField = {id: 'last_state_change_date', caption: "Letzte Zustandsänderung", inputKind: "text", showInTable: true, user_editable: false, admin_editable: false};
    
    static fields: BenutzerField[] = [
        BenutzerFields.field_anrede, BenutzerFields.field_titel, BenutzerFields.field_rufname, BenutzerFields.field_familienname, 
        BenutzerFields.field_geburtsname, BenutzerFields.field_geburtsdatum, BenutzerFields.field_abschlussjahr, 
        BenutzerFields.field_land, BenutzerFields.field_plz, BenutzerFields.field_ort, BenutzerFields.field_adresszeile1, 
        BenutzerFields.field_adresszeile2, BenutzerFields.field_tel_privat, BenutzerFields.field_tel_arbeit, 
        BenutzerFields.field_tel_mobil, BenutzerFields.field_email, BenutzerFields.field_is_mitglied, BenutzerFields.field_is_lehrkraft,
        BenutzerFields.field_aktiviert, BenutzerFields.field_state, BenutzerFields.field_last_state_change_date
    ]


}