import { AngebotType, Angebote } from "../../Angebote.js";
import { AlleAngeboteRepsonse, Angebot, BaseResponse, DeleteAngebotRequest, SaveAngebotRequest, SaveAngebotResponse } from "../../Data.js";
import { HttpTool } from "../../tools/Http.js";
import { Modal } from "../../tools/Modal.js";
import { SelectComponent } from "../../tools/SelectComponent.js";
import { Tab, TabsBuilder } from "../TabsBuilder.js";
import { ColumnDefinition, TabulatorFull as Tabulator } from 'tabulator-tables';
import "/node_modules/tabulator-tables/dist/css/tabulator.min.css"

export class MeineAngeboteTab extends Tab {

    selectedAngebotTyp: AngebotType = Angebote.typesWithFields[0];
    table: Tabulator;
    angebote: Angebot[] = [];
    $contentBottom: JQuery<HTMLDivElement>;

    constructor(tabsBuilder: TabsBuilder) {
        super(tabsBuilder, "Meine Angebote", "meine-angebote-tab");
        this.$contentDiv.empty();

        let $contentTop = $(this.getHtmlUpperContent());
        this.$contentDiv.append($contentTop);
        this.$contentBottom = $('<div></div>');
        this.$contentDiv.append(this.$contentBottom);

        this.fetchData().then(() => {
            this.showAngebote();
        });
    }

    async fetchData() {
        let response: AlleAngeboteRepsonse = await HttpTool.fetchSimple("/servlet/getMeineAngebote", {});

        if (response.status == "OK") {

            this.angebote = response.angebotListe;

        } else {
            alert("Die Liste der Angebote konnte nicht geladen werden." + response.message);

        }

    }

    showAngebote() {

        let columns: ColumnDefinition[] = this.selectedAngebotTyp.fields.map((f) => { return { title: f.caption, field: f.id } });

        columns.push(
            {
                title: 'Kommentar',
                formatter: (cell) => {
                    let data: Angebot = cell.getRow().getData();
                    if (data.kommentar?.length > 1) {
                        let $button = $(`<button class="btn btn-primary btn-sm bi-search"> ansehen...</button>`);
                        $button.on('click', () => {
                            Modal.show("Kommentar zum Angebot:", `${data.kommentar}`)
                        });
                        return $button[0];
                    } else {
                        return $('<div></div>')[0];
                    }
                }
            },
            {
                title: 'Bearbeiten',
                formatter: (cell) => {
                    let $button = $(`<button class="btn btn-primary btn-sm bi-pencil"></button>`);
                    $button.on('click', () => {
                        let data: Angebot = cell.getRow().getData();
                        this.tabsBuilder.main.editAngebotControl.showModal("Angebot bearbeiten", data, (a1) => { this.saveAngebot(a1) })
                    })
                    return $button[0];
                }
            }

        )

        if (this.table != null){
            this.table.destroy();
            this.$contentBottom.remove();
            this.$contentBottom = $('<div></div>');
            this.$contentDiv.append(this.$contentBottom);    
        } 

        this.table = new Tabulator(this.$contentBottom[0], {
            columns: columns,
            data: this.angebote,
            layout: "fitDataTable",
            height: "calc(100vh - 450px)",
            selectable: false
        })

        this.table.on('tableBuilt', () => {
            this.table.addFilter('typ', '=', this.selectedAngebotTyp.id);
        })
    }

    async deleteAngebot(a: Angebot) {
        let request: DeleteAngebotRequest = {
            id: a.id
        }
        let resp: BaseResponse = await HttpTool.fetchSimple("/servlet/deleteAngebot", request);
        if (resp != null && resp.status == "OK") {
            this.angebote.splice(this.angebote.indexOf(a), 1);
            this.showAngebote();
            this.tabsBuilder.main.alleAngeboteTab.fetchData();
        }
    }

    async saveAngebot(a: Angebot) {
        let request: SaveAngebotRequest = {
            angebot: a
        }

        await HttpTool.fetchSimple('/servlet/saveAngebot', request);

        this.showAngebote();
        this.tabsBuilder.main.alleAngeboteTab.fetchData();

    }

    getHtmlUpperContent(): JQuery<HTMLDivElement> {
        let $upperDiv = <JQuery<HTMLDivElement>>$('<div></div>');
        let $caption = $('<h3>Meine Angebote für Schüler/innen und Student/innen</h3>')
        $upperDiv.append($caption);

        let $selectDiv = $('<div id="meineAngeboteSelectDiv"></div>');
        $upperDiv.append($selectDiv);

        let select = new SelectComponent<AngebotType>('Art des Angebots:', $selectDiv, (item) => {
            this.selectedAngebotTyp = item.object;
            this.showAngebote();
        });

        for (let a of Angebote.typesWithFields) {
            select.addItem(a.caption, a.id, a);
        }

        let $buttonNew = $(`
        <button type="button" class="btn btn-success btn-sm bi-plus-lg" style="height: 38px; margin-left: 40px"> Neues Angebot erstellen</button>
        `);
        $selectDiv.append($buttonNew);

        $buttonNew.on('click', () => {

            this.tabsBuilder.main.editAngebotControl.showModal('Neues Angebot erstellen', null, async (a: Angebot) => {
                let request: SaveAngebotRequest = {
                    angebot: a
                }

                let response: SaveAngebotResponse = await HttpTool.fetchSimple('/servlet/saveAngebot', request);

                a.id = response.id;

                this.angebote.push(a);
                
                this.showAngebote();
                this.tabsBuilder.main.alleAngeboteTab.fetchData();
                
            })


        })

        return $upperDiv;
    }
}