import { AngebotType, Angebote } from "../../Angebote.js";
import { AlleAngeboteRepsonse, Angebot, AngebotMitBenutzer, BaseResponse, DeleteAngebotRequest, SaveAngebotRequest } from "../../Data.js";
import { HttpTool } from "../../tools/Http.js";
import { Modal } from "../../tools/Modal.js";
import { SelectComponent } from "../../tools/SelectComponent.js";
import { Tab, TabsBuilder } from "../TabsBuilder.js";
import { ColumnDefinition, TabulatorFull as Tabulator } from 'tabulator-tables';
import "/node_modules/tabulator-tables/dist/css/tabulator.min.css"

export class AngeboteVerwaltenTab extends Tab {

    selectedAngebotTyp: AngebotType = Angebote.typesWithFields[0];
    table: Tabulator = null;
    angebote: AngebotMitBenutzer[] = [];
    $contentBottom: JQuery<HTMLDivElement>;

    constructor(tabsBuilder: TabsBuilder) {
        super(tabsBuilder, "Angebote verwalten", "angebote-verwalten-tab");
        this.$contentDiv.empty();

        let $contentTop = $(this.getHtmlUpperContent());
        this.$contentDiv.append($contentTop);
        this.$contentBottom = $('<div></div>');
        this.$contentDiv.append(this.$contentBottom);

        this.fetchData().then(() => {
            this.showAngebote();
        });
    }

    async fetchData() {
        let response: AlleAngeboteRepsonse = await HttpTool.fetchSimple("/servlet/alleAngeboteAuchInaktiv", {});

        if (response.status == "OK") {

            this.angebote = response.angebotListe;
            this.angebote.forEach((a) => { a.name = (a.anrede == "H" ? "Herr" : "Frau") + " " + a.titel + " " + a.rufname + " " + a.familienname })
            this.showAngebote();

        } else {
            alert("Die Liste der Angebote konnte nicht geladen werden." + response.message);

        }

    }

    showAngebote() {

        let columns: ColumnDefinition[] = this.selectedAngebotTyp.fields.map((f) => { return { title: f.caption, field: f.id, headerFilter: true } });
        columns.unshift({ title: "Name", field: "name", headerFilter: true });

        let that = this;

        columns.push(
            {
                title: 'Kommentar',
                formatter: (cell) => {
                    let data: AngebotMitBenutzer = cell.getRow().getData();
                    if (data.kommentar?.length > 1) {
                        let $button = $(`<button class="btn btn-primary btn-sm bi-search"> ansehen...</button>`);
                        $button.on('click', (e) => {
                            Modal.show("Kommentar zum Angebot:", `${data.kommentar}`)
                            e.stopPropagation();
                        });
                        return $button[0];
                    } else {
                        return $('<div></div>')[0];
                    }
                }
            },

            {
                title: 'Aktiv',
                field: 'aktiv',
                formatter: (cell, formatterParams, onRendered) => {
                    if (cell.getValue()) {
                        return $(`<i class='bi-check des-check'></i>`)[0];
                    } else {
                        return $(`<i class='bi-x des-cross'></i>`)[0];
                    }
                },
                hozAlign: "center",
                cellClick: async (e, cell) => {
                    let newValue = !cell.getValue();
                    let id = <number>cell.getRow().getData().id;
                    let angebot = that.angebote.find(a => a.id == id);
                    angebot.aktiv = newValue;
                    await that.saveAngebot(angebot, false);
                    cell.setValue(newValue);
                }
            },

            {
                title: 'Bearbeiten',
                formatter: (cell) => {
                    let data: AngebotMitBenutzer = cell.getRow().getData();
                    let $button = $(`<button class="btn btn-primary btn-sm bi-pencil"></button>`);
                    $button.on('click', () => {
                        let id = data.id;
                        let angebot = that.angebote.find(a => a.id == id);
                        this.tabsBuilder.main.editAngebotControl.showModal("Angebot bearbeiten", angebot, (a1) => { this.saveAngebot(a1) })
                    });
                    return $button[0];
                }
            },

            {
                title: 'Löschen',
                formatter: (cell) => {
                    let data: AngebotMitBenutzer = cell.getRow().getData();
                    let $button = $(`<button class="btn btn-danger btn-sm bi-x-lg"></button>`);
                    $button.on('click', () => {
                        let id = data.id;
                        let angebot = that.angebote.find(a => a.id == id);
                        if (confirm("Soll das Angebot wirklich gelöscht werden?")) {
                            this.deleteAngebot(angebot);
                        }
                    });
                    return $button[0];
                }
            },

        )



        if (this.table != null){
            this.table.destroy();
            this.$contentBottom.remove();
            this.$contentBottom = $('<div></div>');
            this.$contentDiv.append(this.$contentBottom);    
        } 

        this.table = new Tabulator(this.$contentBottom[0], {
            columns: columns,
            data: this.angebote,
            layout: "fitDataTable",
            height: "calc(100vh - 450px)",
            selectable: false
        })

        this.table.on('tableBuilt', () => {
            this.table.addFilter('typ', '=', this.selectedAngebotTyp.id);
        })
    }

    async deleteAngebot(a: Angebot) {
        let request: DeleteAngebotRequest = {
            id: a.id
        }
        let resp: BaseResponse = await HttpTool.fetchSimple("/servlet/deleteAngebot", request);
        if (resp != null && resp.status == "OK") {
            this.angebote.splice(this.angebote.indexOf(<AngebotMitBenutzer>a), 1);
            this.showAngebote();
            this.tabsBuilder.main.alleAngeboteTab.fetchData();
        }
    }

    async saveAngebot(a: Angebot, redraw: boolean = true) {
        let request: SaveAngebotRequest = {
            angebot: a
        }

        await HttpTool.fetchSimple('/servlet/saveAngebot', request);

        if (redraw) this.showAngebote();
        this.tabsBuilder.main.alleAngeboteTab.angebote = this.angebote.filter(a => a.aktiv);
        this.tabsBuilder.main.alleAngeboteTab.showAngebote();

    }

    getHtmlUpperContent(): JQuery<HTMLDivElement> {
        let $upperDiv = <JQuery<HTMLDivElement>>$('<div></div>');

        let $selectDiv = $('<div id="angeboteVerwaltenDiv"></div>');
        $upperDiv.append($selectDiv);

        let select = new SelectComponent<AngebotType>('Art des Angebots:', $selectDiv, (item) => {
            this.selectedAngebotTyp = item.object;
            this.showAngebote();
        });

        for (let a of Angebote.typesWithFields) {
            select.addItem(a.caption, a.id, a);
        }

        return $upperDiv;
    }
}