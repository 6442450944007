import { BenutzerField, BenutzerFields } from "../../BenutzerFields.js";
import { AlleBenutzerRepsonse, BaseResponse, BenutzerData, DeleteBenutzerRequest, SaveBenutzerRequest, SaveBenutzerResponse } from "../../Data.js";
import { HttpTool } from "../../tools/Http.js";
import { Tab, TabsBuilder } from "../TabsBuilder.js";
import { ColumnDefinition, TabulatorFull as Tabulator } from 'tabulator-tables';
import "/node_modules/tabulator-tables/dist/css/tabulator.min.css"

export class NutzerVerwaltenTab extends Tab {

    table: Tabulator;
    benutzer: BenutzerData[] = [];
    $contentBottom: JQuery<HTMLDivElement>;

    constructor(tabsBuilder: TabsBuilder) {
        super(tabsBuilder, "Benutzer verwalten", "benutzer-verwalten-tab");
        this.$contentDiv.empty();

        let $contentTop = $(this.getHtmlUpperContent());
        this.$contentDiv.append($contentTop);
        this.$contentBottom = $('<div></div>');
        this.$contentDiv.append(this.$contentBottom);

        this.table = new Tabulator(this.$contentBottom[0], {
            data: [],
            columns: [
                { title: "Name", field: "name", sorter: "string", width: 200 }
            ]
        })
        this.fetchData();
    }

    async fetchData() {
        let response: AlleBenutzerRepsonse = await HttpTool.fetchSimple("/servlet/alleBenutzer", {});

        if (response.status == "OK") {

            this.benutzer = response.benutzerListe;
            this.aggregateBenutzerFields();
            this.showBenutzer();

        } else {
            alert("Die Liste der Beutzer konnte nicht geladen werden." + response.message);

        }

    }

    aggregateBenutzerFields() {
        for (let b of this.benutzer) {
            b.anschrift = `<div><div>${b.adresszeile1}</div><div>${b.adresszeile2}</div><div>${b.plz} ${b.ort}</div>${b.land != null && b.land.length > 0 ? "<div>" + b.land + "</div></div>" : ""}`;
            b.name = `<div>${b.anrede == "H" ? "Herr " : "Frau "}${b.titel != null && b.titel.length > 0 ? b.titel + " " : ""} ${b.rufname} ${b.familienname}</div>${b.geburtsname != null && b.geburtsname.length > 0 ? "<div>(geb. " + b.geburtsname + ")</div>" : ""}`
            if (typeof b.geburtsdatum == "string") {
                b.geburtsdatum = new Date(b.geburtsdatum);
            }
        }
    }

    setupColumnDefinition(f: BenutzerField): ColumnDefinition {
        let that = this;
        switch (f.inputKind) {
            case "text":
                return { title: f.caption, field: f.id, sorter: "string"};
            case "int":
                return { title: f.caption, field: f.id, sorter: "number", headerFilter: true};
            case "date":
                return {
                    title: f.caption, field: f.id, sorter: "string", formatter: (cell, formatterParams, onRendered) => {
                        return cell.getValue() == null ? "" : (<Date>cell.getValue()).toLocaleDateString()
                    },
                    headerFilter: true
                };
            case "boolean":
                return {
                    title: f.caption, field: f.id, sorter: "boolean", 
                    formatter: (cell, formatterParams, onRendered) => { 
                        if(cell.getValue()){
                            return $(`<i class='bi-check des-check'></i>`)[0];
                        } else {
                            return $(`<i class='bi-x des-cross'></i>`)[0];
                        }
                    }, 
                    hozAlign: "center",
                    cellClick: async (e, cell) => {
                        let newValue = !cell.getValue();
                        let id = <number>cell.getRow().getData().id;
                        let benutzer = that.benutzer.find(a => a.id == id);
                        benutzer[f.id] = newValue;
                        await that.saveBenutzer(benutzer, null, false);
                        cell.setValue(newValue);
                    }
                };
        }
    }


    showBenutzer() {

        let columns: ColumnDefinition[] = BenutzerFields.fields.filter((f) => f.showInTable).map((f) => { return this.setupColumnDefinition(f) });
        columns.unshift({ title: "Anschrift", field: "anschrift", sorter: "string", formatter: (cell) => {
            return $(cell.getValue())[0]
        } });
        columns.unshift({ title: "Name", field: "name", sorter: "string", formatter: (cell) => {return $(cell.getValue())[0]}, headerFilter: true });

        columns.push(
            {
                title: 'Aktionen',
                formatter: (cell, formatterParams, onRendered) => {
                    let that = this;
                    let $b1 = $('<button class="btn btn-primary btn-sm bi-pencil buttonEdit" ></button>')
                    $b1.on('click', () => {
                        let id = <number>cell.getRow().getData().id;
                        let benutzer = that.benutzer.find(a => a.id == id);
                        this.tabsBuilder.main.editBenutzerControl.showModal("Benutzer bearbeiten", benutzer, (b1, newPassword) => { this.saveBenutzer(b1, newPassword) }, true)
                    });

                    let $b2 = $('<button class="btn btn-danger btn-sm bi-x-lg" ></button>')
                    $b2.on('click', () => {
                        let id = <number>cell.getRow().getData().id;
                        let benutzer = that.benutzer.find(a => a.id == id);
                        if (confirm("Soll der Benutzer wirklich gelöscht werden?")) {
                            this.deleteBenutzer(benutzer);
                        }
                    });

                    let $container = $('<div class="buttonContainer"></div>')
                    $container.append($b1, $b2);

                    return $container[0];
                }
            }
        )
        this.table.destroy();

        this.table = new Tabulator(this.$contentBottom[0], {
            columns: columns,
            data: this.benutzer,
            layout: "fitDataFill",
            height: "calc(100vh - 300px)",
            selectable: false
        })

    }

    async deleteBenutzer(a: BenutzerData) {
        let request: DeleteBenutzerRequest = {
            id: a.id
        }
        let resp: BaseResponse = await HttpTool.fetchSimple("/servlet/deleteBenutzer", request);
        if (resp != null && resp.status == "OK") {
            this.benutzer.splice(this.benutzer.indexOf(a), 1);
            this.showBenutzer();
            this.tabsBuilder.main.jahrgangTab.data = this.benutzer.filter(a => a.aktiviert);
            this.tabsBuilder.main.jahrgangTab.updateGrid();
        }
    }

    async saveBenutzer(a: BenutzerData, newPassword: string, redraw: boolean = true) {
        let request: SaveBenutzerRequest = {
            benutzer: a,
            newPassword: newPassword
        }

        await HttpTool.fetchSimple('/servlet/saveBenutzer', request);

        if (redraw) this.showBenutzer();
        this.tabsBuilder.main.jahrgangTab.data = this.benutzer.filter(a => a.aktiviert);
        this.tabsBuilder.main.jahrgangTab.updateGrid();

    }

    getHtmlUpperContent(): JQuery<HTMLDivElement> {
        let $upperDiv = <JQuery<HTMLDivElement>>$('<div style="height: 50px"></div>');

        let $buttonNew = $(`
        <button type="button" class="btn btn-success btn-sm bi-plus-lg" id="buttonNewUser" style="height: 38px;"> Neuen Benutzer erstellen</button>
        `);
        $upperDiv.append($buttonNew);

        $buttonNew.on('click', () => {

            this.tabsBuilder.main.editBenutzerControl.showModal('Neuen Benutzer erstellen', null, async (a: BenutzerData, pw: string) => {
                let request: SaveBenutzerRequest = {
                    benutzer: a,
                    newPassword: pw
                }

                let response: SaveBenutzerResponse = await HttpTool.fetchSimple('/servlet/saveBenutzer', request);

                a.id = response.id;

                this.benutzer.push(a);
                this.aggregateBenutzerFields();
                this.showBenutzer();
                this.tabsBuilder.main.jahrgangTab.fetchData();

            }, true)


        })


        return $upperDiv;
    }

    onTabActivated(): void {
        this.table?.redraw();
    }
}