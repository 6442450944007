
export type SelectItem<S> = {
    caption: string,
    value: string,
    object: S,
    $html: JQuery<HTMLOptionElement>
    onSelect: () => void
}

export class SelectComponent<T> {

    $component: JQuery<HTMLSelectElement>;
    items: SelectItem<T>[] = [];

    constructor(label: string, parent: JQuery<HTMLElement>, public onSelect?: (item: SelectItem<T>) => void) {
        this.$component = $(`<select class="form-select" style="max-width: 300px; margin-bottom: 15px" aria-label="${label}">`);
        parent.append(this.$component);
        let that = this;
        this.$component.on('change', function() {
            let optionSelected = $(this).find("option:selected");
            let valueSelected = optionSelected.val();
            // let textSelected = optionSelected.text();
            for(let item of that.items){
                if(item.value == valueSelected){
                    if(item.onSelect != null) item.onSelect();
                    if(that.onSelect != null) that.onSelect(item);
                }
            }
        })
    }

    addItem(caption: string, value: string, object: T, onSelect?: () => void) {
        let $option = <JQuery<HTMLOptionElement>>$(`<option value="${value}">${caption}</option>`);
        this.$component.append($option);

        let si: SelectItem<T> = {
            caption: caption,
            value: value,
            object: object,
            onSelect: onSelect,
            $html: $option
        };

        this.items.push(si);

    }

    selectByObject(object: T){
        let i = this.items.findIndex(item => item.object == object);
        this.$component.prop("selectedIndex", i);
    }

    selectByValue(value: string){
        let i = this.items.findIndex(item => item.value == value);
        this.$component.prop("selectedIndex", i);

    }

}